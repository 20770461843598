@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Instrument+Serif:ital@0;1&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and borders are included in the element’s width and height */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Instrument Sans", sans-serif;

  position: relative;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "PolySans", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Instrument Serif", serif;
}
