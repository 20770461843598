.sectionheader {
  padding-top: 80px;
  height: 100%;
  max-height: 850px;
  display: grid;
}

.sectioncontainer {
  width: 100%;
  max-width: 777px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 850px;
  justify-content: center;
  align-items: center;
}

.sectioncontainer2 {
  width: 100%;
  max-width: 777px;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-height: 850px;
  justify-content: center;
  align-items: center;
}

.sectionheadertitle {
  font-family: Instrument Serif;
  font-size: 72px;
  font-weight: 400;
  line-height: 83px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sectioncontainertitle {
  font-family: Instrument Serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 61.6px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 566px;
}

.sectionheaderparagraph {
  font-family: Instrument Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.96px;
  letter-spacing: -0.02em;
  text-align: center;
  padding: 40px 0;
}

.sectioncontainerparagraph {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 40px 0;
  max-width: 494px;
}

.search-container {
  width: 470px;
  position: relative;
  display: inline-block;
}

.invisiblebackground {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;

  background-color: rgba(255, 255, 255, 0);
}

.src-input-search {
  display: flex;
  width: inherit;
  gap: 20px;
}
.src-input-searchcontainer {
  margin-top: 2px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  width: inherit;
  box-shadow: 0px 26px 40px 0px #0000000d;
  padding: 8px 24px 8px 24px;
  gap: 0px;
  border-radius: 20px;
  border: 1px 0px 0px 0px;
  max-height: 372px;
  overflow-y: scroll;
  z-index: 10;
}

.src-input-searchcontainer,
.sectioncontainertasklist {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.src-input-searchcontainer::-webkit-scrollbar,
.sectioncontainertasklist::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.src-input-search input {
  width: 100%;
  height: 52px;
  padding: 16px 21px 16px 21px;
  gap: 10px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  border: 1px solid #a2aaa5;
  outline: none;
}

.src-input-search input::placeholder {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  color: #a2aaa5;
}

.searchbutton {
  width: 62px;
  height: 52px;
  padding: 14px 19px 14px 19px;
  gap: 10px;
  border-radius: 16px;

  background: #80ffb5;
  cursor: pointer;
}

.searchbutton svg {
  width: 24px;
}

.searchitems {
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sectioncontainertasklist {
  display: flex;
  gap: 50px;
  max-width: 700px;
  overflow-x: scroll;
  margin-bottom: 50px;
  padding: 0 16px;
}

.sectiontaskitem {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectiontaskimg {
  width: 70px;
  height: 64px;
  gap: 0px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;

  border: 1px solid #a2aaa5;
  margin-bottom: 5px;
}

.sectiontasktitle {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
}

.sectioncontainer3 {
  height: 558px;
  gap: 0px;
  border-radius: 32px;

  background: #16291e;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 652px 1fr;
  margin-bottom: 150px;
}

.sectioncontainer4 {
  text-align: center;
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.sectioncontainer3-left {
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sectioncontainer3-right {
  background: #c5c5c5;
  border-radius: 0px 32px 32px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sltoptitle {
  font-family: Instrument Serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 64.96px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #fff;
}

.sltoptitle span {
  font-family: Instrument Serif;
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  line-height: 64.96px;
  letter-spacing: -0.01em;
  text-align: left;
  color: lightgreen;
}

.sltoplist {
  margin-top: 20px;
  display: flex;
  gap: 10px 60px;
  flex-wrap: wrap;
}

.sltoplist-item {
  display: flex;
  gap: 10px;
}

.sltopparagraph {
  text-wrap: nowrap;
}

.sltopparagraph {
  font-family: Instrument Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 37.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
}

.slbottombutton {
  width: 133px;
  height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 16px;
  background: #80ffb5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  border: none;
}

.s4title {
  font-family: Instrument Serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 61.6px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 50px;
}

.s4itemlist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: start;
  align-items: start;
  gap: 30px;
}

.s4itemlistt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  align-items: start;
  gap: 30px;
  margin-top: 30px;
}

.s4items {
  background: #16291e0d;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 293px;
  align-self: flex-start;
}

.s4itemsn {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 35.2px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
}

.s4itemtitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 35.2px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.s4itemparagraph {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.s4itembutton {
  width: Hug (110px) px;
  height: Hug (45px) px;
  padding: 14px 19px 14px 19px;
  gap: 10px;
  border-radius: 16px;

  background: #80ffb5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
  border: none;
  cursor: pointer;
}

.sectioncontainer5 {
  background: #80ffb5;
  padding: 100px 0px 0px 0px;
}

.sectioncontainer6 {
  height: 483px;
  gap: 0px;
  border-radius: 32px;

  background: #16291e;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 652px 1fr;
  margin-bottom: 150px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.s6title {
  font-family: Instrument Serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 61.6px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  max-width: 455px;
}

.s6paragraph {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.slbottombutton6 {
  width: 184px;
  height: 45px;
  padding: 14px 19px 14px 19px;
  gap: 10px;
  border-radius: 16px;

  background: #80ffb5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
  border: none;
  cursor: pointer;
}

.section7items {
  gap: 0px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.section7item {
  height: 49px;
  padding: 12px 26px 12px 26px;
  gap: 10px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  border: 1px solid #16291e;
  cursor: pointer;
}

.section7itemparagraph {
  text-wrap: nowrap;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
}

.sectionbook {
  height: 458px;
}

.sectionbookcontainer {
  height: 50vh;
}
.sectionbooknow {
  margin: 0 auto;
}

@media only screen and (max-width: 500px) {
  .sectionheader {
    padding-top: 80px;
    height: 100%;
    max-height: 100%;
    display: grid;
  }

  .sectioncontainer {
    width: 100%;
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 850px;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .sectioncontainer2 {
    width: 100%;
    max-width: 777px;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-height: 850px;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .sectionheadertitle {
    font-family: Instrument Serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .sectioncontainertitle {
    font-family: Instrument Serif;
    font-size: 56px;
    font-weight: 400;
    line-height: 61.6px;
    letter-spacing: -0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 566px;
  }

  .sectionheaderparagraph {
    font-family: Instrument Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: -0.02em;
    text-align: center;

    padding: 40px 0;
  }

  .sectioncontainerparagraph {
    font-family: Instrument Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.8px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 40px 0;
    max-width: 100%;
  }

  .search-container {
    width: 100%;
    position: relative;
    display: grid;
  }

  .invisiblebackground {
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9;

    background-color: rgba(255, 255, 255, 0);
  }

  .src-input-search {
    display: flex;
    width: inherit;
    gap: 5px;
  }
  .src-input-searchcontainer {
    margin-top: 50px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    width: 100%;
    box-shadow: 0px 26px 40px 0px #0000000d;
    padding: 8px 24px 8px 24px;
    gap: 0px;
    border-radius: 20px;
    border: 1px 0px 0px 0px;
    max-height: 372px;
    overflow-y: scroll;
    z-index: 10;
  }

  .src-input-searchcontainer,
  .sectioncontainertasklist {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  .src-input-searchcontainer::-webkit-scrollbar,
  .sectioncontainertasklist::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }

  .src-input-search input {
    width: 100%;
    height: 52px;
    padding: 16px 21px 16px 21px;
    border-radius: 16px;
    border: 1px solid #a2aaa5;
    outline: none;
  }

  .src-input-search input::placeholder {
    font-family: Instrument Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.08px;
    letter-spacing: -0.02em;
    color: #a2aaa5;
  }

  .searchbutton {
    width: 62px;
    height: 52px;
    padding: 14px 19px 14px 19px;
    border-radius: 16px;
    background: #80ffb5;
    cursor: pointer;
  }

  .searchbutton svg {
    width: 24px;
  }

  .searchitems {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .sectioncontainertasklist {
    display: flex;
    gap: 50px;
    max-width: 400px;
    overflow-x: scroll;
    margin-bottom: 50px;
    padding: 0 16px;
  }

  .sectiontaskitem {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sectiontaskimg {
    width: 70px;
    height: 64px;
    gap: 0px;
    border-radius: 16px;
    border: 1px 0px 0px 0px;

    border: 1px solid #a2aaa5;
    margin-bottom: 5px;
  }

  .sectiontasktitle {
    font-family: Instrument Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.52px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .sectioncontainer3 {
    height: 100%;
    gap: 0px;
    border-radius: 32px;
    max-height: 100%;
    background: #16291e;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .section3 {
    padding: 16px;
  }

  .sectioncontainer4 {
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 100px;
  }

  .sectioncontainer3-left {
    padding: 20px 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .sectioncontainer3-right {
    background: #c5c5c5;
    border-radius: 0px 0px 32px 32px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    width: 100%;
  }

  .sltoptitle {
    font-family: Instrument Serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 37.12px;
    letter-spacing: -0.01em;
    text-align: left;

    color: #fff;
  }

  .sltoptitle span {
    font-family: Instrument Serif;
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 37.12px;
    letter-spacing: -0.01em;
    text-align: left;

    color: lightgreen;
  }

  .sltoplist {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    flex-wrap: wrap;
  }

  .sltoplist-item {
    display: flex;
    gap: 10px;
  }

  .sltopparagraph {
    text-wrap: nowrap;
  }

  .sltopparagraph {
    font-family: Instrument Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 37.2px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;
  }

  .slbottombutton {
    width: 133px;
    height: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 16px;
    background: #80ffb5;
    font-family: Instrument Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: -0.02em;
    border: none;
  }

  .s4title {
    font-family: Instrument Serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .s4itemlist {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .s4itemlistt {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
  }

  .s4items {
    background: #16291e0d;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .s4itemsn {
    font-family: Instrument Serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 20px;
  }

  .s4itemtitle {
    font-family: Instrument Serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 10px;
  }

  .s4itemparagraph {
    font-family: Instrument Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.8px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .s4itembutton {
    width: Hug (110px) px;
    height: Hug (45px) px;
    padding: 14px 19px 14px 19px;
    gap: 10px;
    border-radius: 16px;

    background: #80ffb5;
    font-family: Instrument Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #16291e;
    border: none;
    cursor: pointer;
  }

  .sectioncontainer5 {
    background: #80ffb5;
    padding: 100px 0px 0px 0px;
  }

  .sectioncontainer6 {
    height: 483px;
    gap: 0px;
    border-radius: 32px;
    background: #16291e;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 150px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .s6title {
    font-family: Instrument Serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 52.8px;
    letter-spacing: -0.01em;
    text-align: center;

    color: #ffffff;
    max-width: 100%;
  }

  .s6paragraph {
    font-family: Instrument Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.8px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #ffffff;
  }

  .slbottombutton6 {
    width: 184px;
    height: 45px;
    padding: 14px 19px 14px 19px;
    gap: 10px;
    border-radius: 16px;

    background: #80ffb5;
    font-family: Instrument Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: -0.02em;
    color: #16291e;
    border: none;
    cursor: pointer;
  }

  .section7items {
    gap: 0px;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .section7item {
    height: 43px;
    padding: 12px 26px 12px 26px;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid #16291e;
    cursor: pointer;
  }

  .section7itemparagraph {
    font-family: Instrument Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18.6px;
    letter-spacing: -0.02em;
    text-align: center;

    color: #16291e;
  }

  .sectionbook {
    height: 458px;
  }

  .sectionbookcontainer {
    height: 50vh;
  }
  .sectionbooknow {
    margin: 0 auto;
  }
}
