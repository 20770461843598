.navbar {
  background-color: #ffffff;
  position: fixed;
  padding: 0 16px;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
}

.navbar.hidden { 
  transform: translateY(-100%);
}

.navbar.visible {
  transform: translateY(0);
}

.navbar-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
}

.navbar-container a {
  text-decoration: none;
}

.navbar-logo-left {
  display: flex;
  gap: 50px;
  align-items: center;
}

.navbar-logo {
  font-size:32px;
  font-weight: 400;
  font-family: "Instrument Serif", serif;
  color: #111;
}

.navbar-logo-middle {
  display: flex;
  gap: 30px;
  align-items: center;
}

.navbar-logo-link {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.navbar-logo-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar-logo-become {
  padding: 14px 19px;
  font-size: 14px;
  font-weight: 500;
  color: #16291E;
  border-radius: 12px;
  background-color: #80FFB5;
}

.navbar-logo-signup,
.navbar-logo-login {
  font-family: inherit;
  font-size: 18px;
  color: #111;
  font-weight: 400;
}

.navbar-mobile {
  display: none;
}

@media only screen and (max-width: 500px) {
  .navbar {
    background-color: #f9f8fb;
    position: fixed;
    padding: 0 16px;
    width: 100%;
  }

  .navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
  }

  .navbar-container a {
    text-decoration: none;
  }

  .navbar-logo-left {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .navbar-logo {
    font-size: 20px;
    font-weight: 900;
    color: #111;
    margin-top: -5px;
  }

  .navbar-logo-middle {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .navbar-logo-link {
    display: none;
    font-size: 14px;
    color: #111;
    font-weight: 500;
  }

  .navbar-logo-right {
    display: none;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .navbar-logo-become {
    display: none;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #000;
    color: #fff;
    border-radius: 12px;
    background-color: #111;
  }

  .navbar-logo-signup {
    display: none;
  }
  .navbar-logo-login {
    font-family: inherit;
    font-size: 14px;
    color: #111;
    font-weight: 500;
    display: none;
  }

  .navbar-mobile {
    display: flex;
  }

  .navbar-mobile button {
    border: none;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .navbar-mobile img {
    width: 24px;
  }
}

