.signuppage {
  height: 100vh;
}

.signuppage-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.signuppage-left {
  max-width: 470px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signuppage-lcnt {
  max-width: 470px;
  width: 100%;
}

.signupwriteup {
  display: grid;
  align-items: center;
  justify-content: center;
}

.signuptitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.signupparagraph {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
  max-width: 470px;
}

.signupp2 {
}

.signupparagraph span {
  font-weight: 600;
}

.signupemailform {
  margin: 20px 0;
  display: grid;
  gap: 10px;
}

.signupemailform input {
  width: 100%;
  height: 60px;
  padding: 20px 17px 20px 17px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #a2aaa5;
  outline: none;
  color: #000;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.bigbtn {
  width: 100%;
  height: 49px;
  padding: 16px 19px 16px 19px;
  gap: 10px;
  border-radius: 16px;
  background: #80ffb5;
  cursor: pointer;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ordivider {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.ordividerr {
  height: 1px;
  background-color: #a2aaa5;
  width: 100%;
}

.signupauthbtn {
  display: grid;
  gap: 16px;
}

.signupauthbtn button {
  width: 470px;
  height: 56px;
  padding: 16px 17px 16px 17px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #a2aaa5;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}

.signupauthbtn button label {
  margin: 0 auto;
  cursor: pointer;
}

.signuppage-right {
  background: #c5c5c5;
}

.signupemailform input {
  max-width: 470px;
  width: 100%;
}

.inputform {
  margin-bottom: 20px;
  display: grid;
  gap: 10px;
}

.inputform input {
  border: 1px solid #dcdcdc;
}

.inputform input::placeholder {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dcdcdc;
}

.phoneinput {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdcdc;
  border-radius: 16px;
}

.phoneinput input {
  border: none;
  border-left: 1px solid #dcdcdc;
  border-right: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.phoneinput label {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dcdcdc;
  padding: 20px 17px 20px 17px;
}

.signupagreement {
  margin-bottom: 30px;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.signupagreement span {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.signupwriteup a {
  text-align: center;
  margin-bottom: 10px;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
  color: #000000;
}

.rescd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.sppla {
  text-align: center;
  margin-bottom: 10px;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-decoration: underline;
  color: #000000;
}

.sppla span {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: center;
  text-decoration: none !important;
}

.signup4typo {
  text-align: left !important;
  align-items: start;
  justify-content: start;
  max-width: 490px;
  width: 100%;
}

.signup4typo h2 {
  text-align: left;
  max-width: 490px;
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.acts4typo {
  color: #a2aaa5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.inputformcountry {
  width: 100%;
  height: 49px;
  padding: 16px 17px 16px 17px;
  gap: 10px;
  border-radius: 16px;
  background: #f2f6f4;
}

.countryname {
  color: #687b70;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
