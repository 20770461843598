.bookstepnav_container {
  padding: 100px 0 40px 0;
  max-width: 572px;
  margin: 0 auto;
}

.bookstepmap {
  display: flex;
  width: 100%;
  align-items: center;
}

.bookstepitemactive {
  width: 24px;
  height: 24px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 0.64px 0px 0px 0px;
  border: 0.64px solid #80ffb5;
  background: #80ffb5;

  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
}

.bookstepitemnotactive {
  width: 24px;
  height: 24px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 0.64px 0px 0px 0px;
  border: 0.64px solid #a2aaa5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #a2aaa5;
}

.bookstepwidthnotactive {
  width: 100%;
  height: 2px;
  background: #a2aaa535;
  border: none;
}

.bookstepwidthactive {
  width: 100%;
  height: 2px;
  background: #80ffb5;
  border: none;
}

.bookstepnav-title {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 30px;
}
