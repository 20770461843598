.schedulestatus {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #ffedd8;
  padding: 6px 10px 6px 10px;
  border-radius: 6px;
  max-width: max-content;
  margin-top: 15px;
}

.schedulestatusgreen {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #edf6f2;

  padding: 6px 10px 6px 10px;
  border-radius: 6px;
  max-width: max-content;
  margin-top: 15px;
}
.mytcnts {
  background-color: #fff;
}

.mytaskcnts {
  max-width: 568px;
}

.schedulestatustext {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #f08b18;
}

.schedulestatustextgreen {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #20945d;
}

.scheduleprofile {
  margin: 30px 0;
}

.scheduleprofiletitle {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #a2aaa5;
}

.sprofile {
  margin: 30px 0 30px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.sprofileleft img {
  width: 146px;
  height: 146px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  border: 8px solid #fff;
  box-shadow: 0px 9.13px 41.97px 0px #16291e1a;
}

.sprofileright {
  display: grid;
  gap: 10px;
}

.spname {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
}

.sptypo {
  display: flex;
  gap: 10px;
}

.sprinfo {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f9f9f9;
  padding: 5px 8px 5px 8px;
  border-radius: 8px;
}

.sprinfopar {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #6c6c6c;
}

.scheduledivider {
  border-bottom: 1px solid #dcdcdc;
}

.taskdtls {
  margin-top: 40px;
  margin-bottom: 40px;
}

.tskdtl-items {
  display: grid;
  gap: 20px;
}

.tskdtll-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tskdtl-ileft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tskdtl-ileft label {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #79827c;
}

.tskdtl-iright label {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.tskdtl-item {
  display: grid;
  gap: 10px;
}

.tsdlir-item {
  max-width: 226px;
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
}

.tsdlirtitle {
  font-family: Instrument Sans;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 19.68px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #79827c !important;
}

.tsdlirpar {
  font-family: Instrument Sans;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.tsrish {
  align-items: start;
}

.tskdtlrightcnt {
  max-width: 362px;
  height: 154px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #16291e33;
  overflow-y: scroll;
}

.tskdtlrightcnt {
  overflow: auto; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox */
}

.tskdtlrightcnt::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.tskdtl-par {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e99;
}

.scheduletbn-cnt {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 20px;
  margin: 40px 0;
}

.schedulebtnmsg {
  width: 100%;
  height: 56px;
  padding: 16px 19px;
  border-radius: 16px;
  background: #80ffb5;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
  cursor: pointer;
}

.schedulebtncancel {
  width: 100%;
  height: 56px;
  padding: 16px 19px;
  border-radius: 16px;
  background: #fff6f4;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ff5521;
  cursor: pointer;
}

.schedulechatcnt {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 20px;
  margin: 50px 0;
  position: relative;
  height: 761px;
  background: #f9f9f9;
  display: grid;
  grid-template-rows: 117px 1fr 106px;
}

.schedulechatcnt-top {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 25px 39px;
}

.sctopimg {
  width: 64px;
  height: 64px;
  border-radius: 100px;
  border: 4px solid #fff;
  box-shadow: 0px 4px 18.4px 0px #16291e1a;
}

.scchtpro {
  display: flex;
  flex-direction: column;
}

.scchtproname {
  margin-bottom: -10px;
  font-family: Instrument Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
}

.schedulechatcnt-bottom {
  box-shadow: 0px -6px 12.5px -7px #0000001a;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  padding: 27px 16px;
  gap: 10px;
  width: 100%;
}

.schatmsg {
  display: flex;
  border: 1px solid #dcdcdc;
  padding: 10px 12px;
  border-radius: 16px;
  gap: 10px;
  width: 100%;
}

.schechat {
  width: 100%;
  border: none;
  outline: none;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.08px;
  letter-spacing: -0.02em;
}

.schechat::placeholder {
  color: #c3c3c3;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.08px;
  letter-spacing: -0.02em;
}

.schimg {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.completetsktitle {
  font-family: Instrument Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.96px;
  letter-spacing: -0.02em;
  color: #16291e;
  margin: 50px 0px;
}

.completedivid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cmpratebtn {
  height: 52px;
  padding: 16px 19px;
  border-radius: 16px;
  border: 1px solid #a2aaa5;
  background-color: transparent;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
  cursor: pointer;
  color: #16291e;
}

.ratingbtns {
  display: flex;
  gap: 10px;
}

.rbtns {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.ratemodal {
  max-height: 283px;
}

.rwmodal {
  max-height: 334px;
}

.rwpar {
  text-align: left;
}

.ratingwordsarea {
  margin-bottom: 16px;
}
