.booktaskstepone_section {
  margin: 0 auto;
  max-width: 871px;
  width: 100%;
  display: grid;
  gap: 20px;
}

.yourlocation {
  padding: 41px 44px 41px 44px;
  gap: 32px;
  border-radius: 32px;
  background: #16291e05;
}

.yltitle {
  font-family: Instrument Serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
  cursor: pointer;
}

.ylinputform {
  width: 100%;
}

.ylinputformcnt {
  margin-bottom: 10px;
  display: grid;
  gap: 10px;
  margin-top: 25px;
}

.ylinputformcnt label {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.ylinputformcnt input {
  border: 1px solid #dcdcdc;
  height: 52px;
  padding: 16px 17px 16px 17px;
  gap: 10px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  background-color: transparent;
  outline: none;
}

.ylinputformcnt input::placeholder {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dcdcdc;
}

.cntbtn {
  padding: 14px 19px 14px 19px;
  gap: 10px;
  border-radius: 16px;
  background: #80ffb5;
  border: none;
  cursor: pointer;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
  margin-top: 20px;
}

.tpquestion {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
  margin: 20px 0;
}

.tskitemactive {
  width: 100%;
  padding: 17px 18px 17px 18px;
  gap: 0px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #16291e;
  background: #16291e05;
  margin-top: 20px;
}

.tskitemnotactive {
  margin-top: 20px;
  width: 100%;
  padding: 17px 18px 17px 18px;
  gap: 0px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
}

.tskitemleft {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tskitemactive label {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.tskitemactive p {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.tskitemnotactive label {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.tskitemnotactive p {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.ylinputform img {
  cursor: pointer;
}

.textarea-container {
  width: 100%;
}

.textarea-container textarea {
  background-color: transparent;
  width: 100%;
  height: 125px;
  padding: 15px;
  font-size: 16px;
  border-radius: 8px;
  resize: none; /* Prevents resizing */
  border: 1px solid #dcdcdc;
  outline: none;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 29.12px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.textarea-container textarea::placeholder {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 29.12px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dcdcdc;
}

.booktasksteptwo_section {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: start;
  gap: 20px;
}

.bttsleft {
  width: 100%;
  max-width: 344px;
  padding: 41px 44px;
  gap: 32px;
  border-radius: 32px;
  background: #16291e05;
}

.bttslefttitle {
  font-family: Instrument Serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
  margin-bottom: 30px;
}

.bttsleftdate_filter {
  display: grid;
  gap: 15px;
}

.btdftitle {
  font-family: Instrument Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 32.8px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.bttsleftitems {
  display: grid;
  gap: 40px;
}

.bttsdf_items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.bttsdf_item {
  padding: 10px 20px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #16291e;
  background-color: transparent;
  cursor: pointer;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.7px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
}

.divider {
  border-bottom: 1px solid #dde4e0;
}

.bttsdftitle {
  font-family: Instrument Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 32.8px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.bttsright {
  border: 1px solid #16291e0d;
  background: #16291e03;
  width: 100%;
  padding: 41px 44px;
  gap: 32px;
  border-radius: 32px;
}

.bttsright-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bttsright-toptitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
}

.bttsright-topright {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.bttsright-topright label {
  cursor: pointer;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.wlitopleftimg {
  width: 64px;
  height: 64px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 4px 18.4px 0px #16291e1a;
  border-radius: 100%;
  border: 4px solid #fff;
}

.workerslist-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 30px;
}

.workerslist-item {
  border: 1px solid #f2f2f2;
  width: 100%;
  max-width: 358px;
  gap: 24px;
  border-radius: 24px;
}

.wlitop {
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.wlitopleft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.wlitopryellow {
  padding: 5px 11px;
  gap: 4px;
  border-radius: 20px;
  background: #f7bb09;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: min-content;
}

.wlitopryellow label {
  color: #fff;
  font-family: Instrument Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.64px;
  letter-spacing: -0.02em;
}

.wlitoprgreen {
  padding: 5px 11px;
  gap: 4px;
  border-radius: 20px;
  background: #20945d;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: min-content;
}

.wlitoprgreen label {
  color: #fff;
  font-family: Instrument Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.64px;
  letter-spacing: -0.02em;
}

.wlitoprighttitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
}

.wlitoprightbottom {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.greatvalue,
.hourminimum {
  text-wrap: nowrap;
  padding: 4px 7px 4px 7px;
  border-radius: 6px;
}

.greatvalue {
  font-family: Instrument Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #33925b;
  background: #e3ffef;
}

.hourminimum {
  background: #ffeeca;
  font-family: Instrument Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #7d6b44;
}

.wlimiddle {
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.wlimleft {
  display: grid;
  /* gap: 10px; */
}

.wlimtask,
.wlimexp {
  color: #b4b4b4;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
}

.wlimright {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.wlimright label {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
  cursor: pointer;
  text-wrap: wrap;
}

.wlibottom {
  box-shadow: 0px -3px 10px 0px #00000008;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
}

.wlibottomprice {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
}

.wlibottombook {
  cursor: pointer;
  padding: 14px 19px 14px 19px;
  gap: 10px;
  border-radius: 16px;
  background: #80ffb5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
  border: none;
}

.overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  position: absolute;
  max-width: 854px;
  width: 100%;
  top: 50%;
  left: 50%;
  height: 80vh;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.modal {
  overflow: auto; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox */
}

.modal::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.modaldtop {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.mdtlimg {
  width: 118px;
  height: 118px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 4px 18.4px 0px #16291e1a;
  border-radius: 100%;
  border: 4px solid #fff;
}

.mdtltitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
  margin-bottom: 10px;
}

.modaldtop-left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.mdtlprcnt {
  display: flex;
  align-items: start;
  gap: 20px;
}

.modaldtop-right {
  font-size: 32px;
  cursor: pointer;
}

.bioexcnt {
  display: grid;
  gap: 30px;
  padding: 0px 30px;
}

.bioexcnt-exp {
  display: grid;
  gap: 10px;
}

.bioexcnt-exp label {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.bioexcnt-par {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #48534c;
}

.workcntph {
  display: flex;
  align-items: center;
  gap: 5px;
}

.workcntimages {
  display: flex;
  gap: 10px;
}

.wrkimg {
  max-width: 124px;
  max-height: 124px;
  object-fit: cover;
  object-position: center;
  border-radius: 24px;
}

.bioexcnt-ritwitemtop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brtilimg {
  width: 54px;
  height: 54px;
  border-radius: 54px;
  object-fit: cover;
  object-position: center;
}

.brtwitemleft {
  display: flex;
  gap: 10px;
  align-items: center;
}

.brtiltitle {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
}

.brtilrate {
  display: flex;
  gap: 5px;
  align-items: center;
}

.brtilratep {
  font-family: Instrument Sans;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.02em;
  text-align: left;
}

.brtwitemright {
  display: flex;
  gap: 10px;
}

.brtwip {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #b4b4b4;
}

.brtwipdate {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #16291e;
}

.ratingwords {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #48534c;
  margin-top: 20px;
}

.wlibottoms {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-top: 1px solid #f2f2f2;
  background-color: #fff;
}

.booktaskstepthree_section {
  display: flex;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.btstsleft {
  max-width: 450px;
  width: 100%;
  padding: 41px 44px 41px 44px;
  gap: 0px;
  border-radius: 32px;
  background: #16291e;
}

.btstsleftitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.btstsleftprofimg {
  width: 56px;
  height: 56px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 4px 18.4px 0px #16291e1a;
  border-radius: 100%;
  border: 4px solid #fff;
}

.btstsleftprof {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btstsleftprofname {
  font-family: Instrument Serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.btstsleftdetails {
  margin-top: 20px;
  display: grid;
  gap: 15px;
  margin-bottom: 20px;
}

.btsdetitem {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btsdeticon {
  width: 24px;
}

.btsdetlabel {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffffb2;
}

.edittaskbtn {
  padding: 14px 19px 14px 19px;
  gap: 10px;
  border-radius: 16px;
  background: #ffffff33;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
  border: none;
}

.tsummarydivider {
  border-bottom: 1px solid #ffffff1a;
  margin: 30px 0;
}

.tsmtasdetails {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
}

.tsmtdetcnt {
  margin-top: 10px;
  width: 100%;
  height: 105px;
  padding: 12px 12px 0px 12px;
  border-radius: 12px;
  border: 1px solid #ffffff33;
  overflow-y: scroll;
}

.tsmtdetcnt {
  overflow: auto; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox */
}

.tsmtdetcnt::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.tsmtdetcntpar {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff99;
}

.pricedetailscnt {
  margin: 10px 0;
  display: grid;
  gap: 20px;
}

.pricedetails-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pdetails {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff99;
}

.detailsprice {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
}

.confirmpaymentbtn {
  width: 100%;
  padding: 16px 19px 16px 19px;
  border-radius: 16px;
  background: #80ffb5;
  border: none;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
  margin-top: 10px;
  cursor: pointer;
}

.btstsright {
  display: grid;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.btstsrighttop {
  background: #16291e05;
  border: 1px solid #16291e08;
  width: 100%;
  padding: 41px 44px 41px 44px;
  border-radius: 32px;
}

.btstsrighttitle {
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
}

.btstsrighttop form {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

.form-group {
  display: grid;
  gap: 8px;
  width: 100%;
}

.form-group label {
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.cardnumbercnt {
  border: 1px solid #d1cece;
  width: 100%;
  padding: 16px 17px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.cardnumbercnt input {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
}

.cardnumbercnt input::placeholder {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #dcdcdc;
}

.card-type-img {
  width: 41.17px;
  height: 32px;
  object-fit: contain;
  object-position: center;
  border: none;
}

.expseccnt {
  display: flex;
  gap: 20px;
}

.expseccnt .form-group input {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  border: 1px solid #d1cece;
  width: 100%;
  padding: 16px 17px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.expseccnt .form-group input::placeholder {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #dcdcdc;
}

.countrycnt {
  border: 1px solid #d1cece;
  width: 100%;
  padding: 16px 17px 16px 17px;
  border-radius: 16px;
}

.successmodal {
  position: absolute;
  max-width: 400px;
  max-height: 372px;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.successmodalbtn {
  width: 100%;
  padding: 14px 19px 14px 19px;
  border-radius: 16px;
  background: #80ffb5;
  font-family: Instrument Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
  border: none;
  cursor: pointer;
}

.btstsleftprofimgg {
  width: 77px;
  height: 77px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 4px 18.4px 0px #16291e1a;
  border-radius: 100%;
  border: 4px solid #fff;
  margin-bottom: 20px;
}

.successmodal h1 {
  margin-bottom: 20px;
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #16291e;
}

.btstslefttitle {
  font-family: Instrument Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.4px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 10px;
}

.btstsleftpara {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
  margin-bottom: 15px;
}
