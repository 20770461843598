.mytcnt {
  background-color: #fafbfb;
  height: 100%;
  padding-bottom: 150px;
}

.mytaskcnt {
  max-width: 768px;
  margin: 0 auto;
  padding-top: 120px;
}

.mytaskcnttitle {
  font-family: Instrument Serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 61.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
}

.mytasknav {
  margin-top: 40px;
}

.mytasknav-btn {
  width: 192px;
  padding: 14px 10px 14px 10px;
  font-family: Instrument Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.96px;
  letter-spacing: -0.02em;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #a2aaa5;
  border-bottom: 3px solid #d1cece;
  cursor: pointer;
}

.mytactive {
  color: #16291e;
  border-bottom: 3px solid #16291e;
}

.mytaskcntsection {
  margin-top: 40px;
  display: grid;
  gap: 30px;
}

.btsdettlabel {
  color: #7f8983;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.mytaskcntitem {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  border-left: 6px solid #ffba25;
  padding: 30px;
}

.mytaskcntitemcompleted {
  border-left: 6px solid #02a517;
}

.mytaskcntitem-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mytaskcntitem-left {
  display: grid;
  gap: 10px;
}

.mytaskitemtitle {
  font-family: Instrument Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #16291e;
}

.mytaskcntitem-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.mytaskcntitem-right img {
  width: 52px;
  height: 52px;
}

.mytaskcntitem-rightname {
  margin-top: -15px;
  font-family: Instrument Serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #16291e;
}

.mytaskviewtbtn {
  
  padding: 16px 19px 16px 19px;
  border-radius: 16px;
  background: #80ffb5;
  border: none;
  color: #16291e;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: left;
  cursor: pointer;
  text-wrap: wrap;
  height: 52px;
}
.mytaskcntitem-bottom {
    display: flex;
    gap: 20px;margin-top: 20px;
}
.mytaskviewbtnv {
  padding: 16px 19px 16px 19px;
  border-radius: 16px;
  border: 1px solid #16291e;
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #16291e;
  background-color: transparent;
  display: flex;
  align-items: center;
  text-wrap: wrap;
  cursor: pointer;
  height: 52px;
}


